import React from "react";
import PT from "prop-types";
import styled from "styled-components";

import { colors, down, up, fonts } from "~/src/config/vars";

const ProjectInfoLink = ({ link, icon, title }) => {
    return (
        <SProjectInfoLink href={link} target="_blank">
            <SIcon src={icon} alt={title} />
            <STitle>{title}</STitle>
        </SProjectInfoLink>
    );
};

const SProjectInfoLink = styled.a`
    display: flex;
    align-items: center;
    font-size: 0.9em;
    color: $blue;
    transition: all 0.1s;
    ${up("xl")} {
        margin-top: 0.5em;
    }
    &:first-of-type {
        margin-top: 0;
    }
    ${down("lg")} {
        margin: 1em 1em 0 0;
    }
    @media (hover) {
        &:hover {
            color: ${colors.red};
        }
    }
    ${down("sm")} {
    }
`;
const SIcon = styled.img`
    width: 1.2em;
    height: 1.2em;
    margin-right: 0.4em;
    filter: drop-shadow(0px 0px 20px #545454);
`;
const STitle = styled.div`
    font-size: ${fonts.fs_20};
    position: relative;
    display: inline-block;
    font-weight: ${fonts.fw_medium};
    z-index: 1;
    color: ${colors.white};
    text-shadow: 0 1px 0 ${colors.black};
    ${SProjectInfoLink}:hover & {
        color: ${colors.white};
        &:after {
            width: 0;
        }
    }

    &:after {
        content: "";
        width: 100%;
        height: 3px;
        background-color: ${colors.red};
        position: absolute;
        bottom: 3px;
        left: 0;
        z-index: -1;
        transition: all 0.3s;
        opacity: 0.8;
    }
`;

ProjectInfoLink.propTypes = {
    link: PT.string,
    icon: PT.string,
    title: PT.string,
};

export default ProjectInfoLink;
