import React, { PureComponent } from "react";
import PT from "prop-types";
import styled from "styled-components";

import { colors, down, fonts } from "~/src/config/vars";

class ProjectStackItem extends PureComponent {
    static propTypes = {
        link: PT.string,
        avatar: PT.string,
        title: PT.string,
        position: PT.string,
    };

    render() {
        const { link, avatar, title, position } = this.props;

        return (
            <SProjectStackItem href={link} target="_blank">
                <SAvatar src={avatar} alt={title} />
                <SInfo>
                    <SName>{title}</SName>
                    <SPosition>{position}</SPosition>
                </SInfo>
            </SProjectStackItem>
        );
    }
}

const SProjectStackItem = styled.a`
    display: flex;
    align-items: center;
    padding-right: 1.8em;
    margin-bottom: 0.8em;
    transition: all 0.2s;
    width: 100%;
    color: ${colors.grey};
    ${down("sm")} {
        width: 100%;
    }
    &:hover {
        color: ${colors.red};
    }
    &:not([href]),
    &:not([href]):hover {
        cursor: default;
        color: ${colors.grey};
    }
`;
const SAvatar = styled.img`
    width: 2.2em;
    height: 2.2em;
    margin-right: 0.5em;
    filter: grayscale(100%);
    transition: all 0.2s;
    opacity: 0.7;
    border-radius: 50%;
    ${SProjectStackItem}[href]:hover & {
        filter: grayscale(0);
        opacity: 1;
    }
`;
const SInfo = styled.div`
    line-height: 1.3;
`;

const SName = styled.div`
    font-size: ${fonts.fs_18};
    font-weight: ${fonts.fw_medium};
`;

const SPosition = styled.div`
    font-size: ${fonts.fs_16};
    color: ${colors.grey_light};
    transition: all 0.2s;
    font-variation-settings: "ital" 100;
    ${SProjectStackItem}[href]:hover & {
        font-variation-settings: "ital" 1000;
    }
`;

export default ProjectStackItem;
