import React from "react";
import PT from "prop-types";
import { graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";

import ProjectStackItem from "./ProjectStackItem";
import { HorizontalScroll } from "~/src/components";
import { up } from "~/src/config/vars";

const ProjectStack = ({ stacks = [] }) => {
    const data = useStaticQuery(graphql`
        query ProjectStacksQuery {
            jsons: allFile(
                filter: {
                    sourceInstanceName: { eq: "stacks" }
                    ext: { eq: ".json" }
                }
            ) {
                nodes {
                    name
                    childStacksJson {
                        id
                        title
                        link
                        icon
                    }
                }
            }
            icons: allFile(
                filter: {
                    sourceInstanceName: { eq: "stacks" }
                    ext: { eq: ".svg" }
                }
            ) {
                nodes {
                    name
                    publicURL
                }
            }
            bg: file(name: { eq: "altamira_bg2" }, ext: { eq: ".png" }) {
                childImageSharp {
                    gatsbyImageData
                }
            }
        }
    `);
    const selected_jsons = data.jsons.nodes.filter((j) => {
        return stacks.includes(j.name);
    });
    selected_jsons.forEach((json) => {
        Object.assign(json, json.childStacksJson);
        json.icon = data.icons.nodes.find((icon) => icon.name === json.name);
    });
    return (
        <HorizontalScroll>
            <SProjectStack>
                {selected_jsons.map((json, idx) => (
                    <ProjectStackItem
                        key={idx}
                        link={json.link}
                        icon={json.icon.publicURL}
                        title={json.title}
                    />
                ))}
            </SProjectStack>
        </HorizontalScroll>
    );
};

const SProjectStack = styled.div`
    display: flex;
    ${up("md")} {
        flex-wrap: wrap;
    }
`;

ProjectStack.defaultProps = {
    stacks: [
        "wordpress",
        "node",
        "vue",
        "prestashop",
        "react_js",
        "saleor",
        "android",
        "ios",
        "django",
        "php",
        "react_native",
        "postgres_ql",
        "elastic_search",
        "java_script",
        "type_script",
        "go",
        "gin",
        "go_libav",
        "ffmpeg",
        "mobx",
        "mysql",
        "mongo_db",
        "jquery",
        "bootstrap",
        "java",
        "json",
        "html",
        "python",
        "apache",
        "nextjs",
        "caddy",
        "styled_components",
        "emotion",
        "i18n",
        "lottie",
        "reatom",
        "css",
        //
        "one_c",
        "facebook",
        "instagram",
        "google_maps",
        "paypal",
        "paybox",
        "lets_encrypt",
        "firstssl",
        "twogis",
        "mailgun",
        "2checkout",
        "telegram",
        //
        "aws_s3",
        "amazon_elastic_search",
        "amazon_rds",
        "amazon_ec2",
        "docker",
        "npm",
        "grunt",
        "eslint",
        "webpack",
        "trello",
        "redux",
        "yarn",
        "redis",
        "prettier",
        "git",
        "travelLine",
        "gitlab",
        "debian",
        "ubuntu",
        "post_css",
        "sass",
        "bower",
        "autoprefixer",
        "sputnik",
        "notion",
        "ansible",
        "figma",
        "adobe_xd",
        "makefile",
        "kubernetes",
        "versel",
        "imgproxy",
    ],
};

ProjectStack.propTypes = {
    stacks: PT.arrayOf(PT.string),
};

export default ProjectStack;
