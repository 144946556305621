import React, { PureComponent } from "react";
import PT from "prop-types";
import styled from "styled-components";

import { colors, fonts, down, global } from "~/src/config/vars";

class ProjectStackItem extends PureComponent {
    static propTypes = {
        link: PT.string,
        icon: PT.string,
        title: PT.string,
    };

    render() {
        const { link, icon, title } = this.props;

        return (
            <SProjectStackItem href={link} target="_blank">
                <SIcon src={icon} alt={title} />
                <SName>{title}</SName>
            </SProjectStackItem>
        );
    }
}

const SProjectStackItem = styled.a`
    display: flex;
    align-items: center;
    margin-right: 1.4em;
    margin-bottom: 0.8em;
    transition: all 0.1s ease-in-out;
    ${down("sm")} {
        margin-bottom: 0;
        margin-right: 1.2em;
        padding: 0.4em 0;
    }
    &:last-of-type {
        padding-right: ${global.container_padding};
    }
`;
const SIcon = styled.img`
    height: 1em;
    margin-right: 0.4em;
    filter: grayscale(100%);
    transition: all 0.1s;
    opacity: 0.7;
    ${SProjectStackItem}:hover & {
        filter: grayscale(0);
        opacity: 1;
    }
    ${down("sm")} {
        filter: grayscale(0);
    }
`;
const SName = styled.div`
    font-size: ${fonts.fs_18};
    color: ${colors.grey};
    white-space: nowrap;
    ${down("sm")} {
        font-size: ${fonts.fs_16};
    }
    ${SProjectStackItem}:hover & {
        color: ${colors.red};
    }
`;

export default ProjectStackItem;
