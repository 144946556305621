import CN from "classnames";
import PT from "prop-types";
import React from "react";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { colors, down, fonts } from "~/src/config/vars";

const ProjectContentScreen = ({
    color = "white",
    title,
    alt,
    link,
    desk,
    image,
    show_top_bar = true,
    show_padding_top = true,
    show_padding_bottom = true,
    bg,
    full = false,
}) => {
    const img = getImage(image);
    return (
        <SProjectContentScreen
            className={CN(`${color}`, {
                full: full,
                show_padding_top: show_padding_top,
                show_padding_bottom: show_padding_bottom,
            })}
            style={{ backgroundColor: bg ? bg : null }}
        >
            {title || desk ? (
                <STop>
                    {title ? <STitle>{title}</STitle> : null}
                    {desk ? <SDesc>{desk}</SDesc> : null}
                </STop>
            ) : null}
            <SBlock>
                <Browser>
                    {show_top_bar === null || show_top_bar === true ? (
                        <BrowserTopBar>
                            <BrowserAddress>
                                <BrowserLink>{link}</BrowserLink>
                            </BrowserAddress>
                        </BrowserTopBar>
                    ) : null}
                    <BrowserContent>
                        <SBrowserImg image={img} alt={title ? title : alt} />
                    </BrowserContent>
                </Browser>
            </SBlock>
        </SProjectContentScreen>
    );
};

ProjectContentScreen.propTypes = {
    color: PT.oneOf(["white", "grey", "black"]).isRequired,
    bg: PT.string,
    title: PT.string,
    alt: PT.string,
    desk: PT.string,
    image: PT.object.isRequired,
    link: PT.string.isRequired,
    //
    show_top_bar: PT.bool,
    show_padding_top: PT.bool,
    show_padding_bottom: PT.bool,
    full: PT.bool,
};

const SProjectContentScreen = styled.div`
    overflow: hidden;
    &.show_padding_top {
        padding-top: 3em;
        ${down("lg")} {
            padding-top: 2em;
        }
    }
    &.show_padding_bottom {
        padding-bottom: 3em;
        ${down("lg")} {
            padding-bottom: 2em;
        }
    }
    &.white {
        background-color: ${colors.white};
        color: ${colors.black};
    }
    &.grey {
        background-color: ${colors.grey_light4};
        color: ${colors.black};
    }
    &.black {
        background-color: ${colors.black};
        color: ${colors.white};
    }
`;
const STop = styled.div`
    padding: 0 3em;
    ${SProjectContentScreen}.full & {
        padding: 0 3em;
        ${down("lg")} {
            padding: 0 1.5em;
        }
        ${down("sm")} {
            padding: 0 15px;
        }
    }
    ${down("lg")} {
        padding: 0 1.5em;
    }
    ${down("sm")} {
        padding: 0 15px;
    }
`;
const STitle = styled.h3`
    font-size: ${fonts.fs_28};
    font-weight: ${fonts.fw_medium};
    margin-bottom: 1em;
`;
const SDesc = styled.div`
    max-width: 650px;
    margin-bottom: 1.4em;
`;
const SBlock = styled.div`
    padding: 0 3em;
    ${down("lg")} {
        padding: 0 1.5em;
    }
    ${down("sm")} {
        padding: 0 15px;
    }
    ${SProjectContentScreen}.full & {
        padding: 0;
    }
`;

const Browser = styled.div`
    overflow: hidden;
    margin: 0 auto;
    user-select: none;
    border-radius: 2px;
    box-shadow: 0 30px 140px -50px rgba(44, 48, 56, 0.5);
    ${SProjectContentScreen}.full & {
        border-radius: 0;
    }
`;
const BrowserTopBar = styled.div`
    display: flex;
    height: 2.5em;
    align-items: center;
    justify-content: center;
    background: #2a2a2a;
    color: ${colors.white};
    ${SProjectContentScreen}.white & {
    }
    ${SProjectContentScreen}.grey & {
    }
    ${SProjectContentScreen}.black & {
    }
    ${down("sm")} {
        height: 2em;
    }
`;
const BrowserAddress = styled.div`
    display: flex;
    width: 75%;
    height: 24px;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    font-size: 0.8em;
    background: #494949;
    ${down("sm")} {
        height: 20px;
        font-size: 0.6em;
    }
`;
const BrowserLink = styled.div`
    opacity: 0.8;
`;
const BrowserContent = styled.div``;
const SBrowserImg = styled(GatsbyImage)`
    width: 100%;
    display: block;
`;

export default ProjectContentScreen;
