import React from "react";
import PT from "prop-types";
import { position } from "polished";
import styled from "styled-components";

import { Above, Scrollbars } from "~/src/components";
import {
    Link,
    ProjectStackBottom,
    ProjectNavigation,
} from "~/src/components/project";
import {
    colors,
    down,
    grid,
    header,
    up,
    fonts,
    global,
} from "~/src/config/vars";

const ProjectInfo = ({
    title,
    sub_title,
    text,
    links,
    stacks,
    integration,
    tools,
    workers,
    prev,
    next,
}) => {
    return (
        <SProjectInfo>
            <Scrollbars
                autoHeight
                autoHeightMin="calc(100vh - 76px)"
                autoHeightMax="100%"
            >
                <SBlock>
                    <STop>
                        <STopBlock>
                            <STitle>{title}</STitle>
                            <SSubTitle>{sub_title}</SSubTitle>
                        </STopBlock>
                        {text ? <SText>{text}</SText> : null}
                    </STop>
                    {links ? (
                        <SCenter>
                            {links ? (
                                <SLinks>
                                    <SLinksBlock>
                                        {links.map((link, idx) => (
                                            <Link
                                                key={idx}
                                                link={link.link}
                                                icon={link.icon.publicURL}
                                                title={link.title}
                                            />
                                        ))}
                                    </SLinksBlock>
                                </SLinks>
                            ) : null}
                        </SCenter>
                    ) : null}
                    <Above from={grid.lg}>
                        <ProjectStackBottom
                            stacks={stacks}
                            integration={integration}
                            tools={tools}
                            workers={workers}
                        />
                    </Above>
                </SBlock>
                <Above from={grid.lg}>
                    <ProjectNavigation prev={prev} next={next} />
                </Above>
            </Scrollbars>
        </SProjectInfo>
    );
};

const padding_right = "1.5em 2em";
const padding_right_lg = "1em 1.5em";
const padding_right_md = "1.5em 1.5em";
const padding_right_sm = `1.5em ${global.container_padding} 10px ${global.container_padding}`;

const SProjectInfo = styled.div`
    ${up("lg")} {
        ${position("fixed", header.height, "0", null, null)};
        height: calc(100% - ${header.height});
        width: 30%;
        border-left: 1px solid ${colors.black_light};
    }
    ${down("sm")} {
    }
`;
const SBlock = styled.div`
    ${up("lg")} {
        padding-bottom: 4em;
    }
`;
const STop = styled.div`
    padding: ${padding_right};
    ${down("lg")} {
        padding: ${padding_right_lg};
    }
    ${down("md")} {
        padding: ${padding_right_md};
    }
    ${down("sm")} {
        padding: ${padding_right_sm};
    }
`;

const STopBlock = styled.div`
    position: relative;
`;
const STitle = styled.h1`
    line-height: 1.2;
    font-size: 1.8em;
    font-weight: ${fonts.fw_black};
    ${down("sm")} {
        font-size: 1.4em;
    }
`;
const SSubTitle = styled.h2`
    font-size: ${fonts.fs_18};
    margin: 0.1em 0 0;
    font-weight: ${fonts.fw_regular};
    color: ${colors.grey_light};
    font-variation-settings: "ital" 500;
    ${down("sm")} {
        margin-bottom: 1em;
    }
`;
const SText = styled.div`
    margin-top: 1em;
    font-size: 0.9em;
    line-height: 1.5;
    color: ${colors.grey_light2};
    font-weight: 100;
`;

const SCenter = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: ${padding_right};
    padding-top: 0;
    ${down("lg")} {
        padding: ${padding_right_lg};
        padding-top: 0;
    }
    ${down("md")} {
        padding: ${padding_right_md};
        padding-top: 0;
    }
    ${down("sm")} {
        padding: ${padding_right_sm};
        padding-top: 0;
        padding-bottom: 2em;
    }
    /* ///// */
    ${up("xl")} {
        justify-content: space-between;
    }
`;
const SLinks = styled.div`
    display: flex;
    margin-right: 1em;
    ${down("lg")} {
        order: 2;
    }
    ${down("sm")} {
        width: 100%;
        margin-right: 0;
    }
`;

const SLinksBlock = styled.div`
    ${down("sm")} {
        display: block;
    }
`;

ProjectInfo.defaultProps = {};

ProjectInfo.propTypes = {
    title: PT.string,
    sub_title: PT.string,
    text: PT.string,
    links: PT.array,
    time: PT.string,
    price: PT.string,
    stacks: PT.array,
    integration: PT.array,
    tools: PT.array,
    workers: PT.array,
    prev: PT.object,
    next: PT.object,
};

export default ProjectInfo;
