import React from "react";
import PT from "prop-types";
import CN from "classnames";
import { position } from "polished";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { colors, down, header } from "~/src/config/vars";

const ProjectContentMain = ({
    show_icon_scroll,
    type,
    image,
    title,
    alt,
    object_fit,
    children,
}) => {
    const img = getImage(image);
    return (
        <SProjectContentMain
            className={CN(
                `${type ? type : "block"} ${object_fit ? object_fit : null}`,
                {
                    show_icon_scroll: show_icon_scroll,
                }
            )}
        >
            <SImage image={img} alt={title ? title : alt} />
            {children}
            {show_icon_scroll ? (
                <SMouse>
                    <SMouseScroll />
                </SMouse>
            ) : null}
        </SProjectContentMain>
    );
};

const SProjectContentMain = styled.div`
    width: 100%;
    position: relative;
    &.absolute {
        height: calc(100vh - ${header.height});
        overflow: hidden;
    }
    ${down("md")} {
        height: auto;
    }
    ${down("sm")} {
    }
`;
const SImage = styled(GatsbyImage)`
    ${SProjectContentMain}.absolute & {
        ${position("absolute", "0", null, null, "0")};
        width: 100%;
        height: 100%;
    }
    ${SProjectContentMain}.cover & {
        object-fit: cover;
    }
    ${SProjectContentMain}.contain & {
        object-fit: contain;
    }
    ${SProjectContentMain}.block & {
        display: block;
        width: 100%;
    }
`;
const SMouse = styled.div`
    ${position("absolute", null, null, "40px", "50%")};
    width: 22px;
    height: 42px;
    margin-left: -12px;
    border-radius: 15px;
    border: 2px solid ${colors.white};
    animation: intro 1s;
    z-index: 3;
`;
const SMouseScroll = styled.div`
    display: block;
    width: 4px;
    height: 4px;
    margin: 8px auto;
    border-radius: 4px;
    background: ${colors.white};
    animation: finger 1s infinite;
    @keyframes finger {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
            transform: translateY(20px);
        }
    }
`;

ProjectContentMain.defaultProps = {
    type: "absolute",
    show_icon_scroll: true,
    object_fit: "cover",
};

ProjectContentMain.propTypes = {
    type: PT.oneOf(["absolute", "block"]).isRequired,
    object_fit: PT.oneOf(["contain", "cover"]),
    show_icon_scroll: PT.bool,
    image: PT.object,
    title: PT.string,
    alt: PT.string,
    children: PT.any,
};

export default ProjectContentMain;
