import React from "react";
import PT from "prop-types";
import { graphql } from "gatsby";
import styled from "styled-components";

import {
    Info,
    ProjectStackBottom,
    ProjectNavigation,
    Main,
    Screen,
} from "~/src/components/project";
import { Below, Layout, MDXProvider, Meta } from "~/src/components";
import { down, grid, up, header } from "~/src/config/vars";

const projectTitleSlugSelector = (edges) =>
    edges.length
        ? {
              title: edges[0].node.frontmatter.title,
              slug: edges[0].node.frontmatter.slug,
          }
        : null;

const ProjectPage = ({ data: { mdx, prev, next } }) => {
    const [prev_link, next_link] = [
        projectTitleSlugSelector(prev.edges),
        projectTitleSlugSelector(next.edges),
    ];

    console.log(
        "project",
        mdx?.frontmatter?.meta_image?.childImageSharp?.gatsbyImageData?.images
            ?.fallback?.src
    );

    return (
        <Layout show_container={false} show_padding={false} show_footer={false}>
            <Meta
                title={mdx.frontmatter.title}
                description={mdx?.frontmatter?.sub_title}
                image={
                    mdx?.frontmatter?.meta_image?.childImageSharp
                        ?.gatsbyImageData?.images?.fallback?.src
                }
                published_time={mdx.frontmatter.created_at}
                modified_time={mdx.frontmatter.updated_at}
            />
            <SProject>
                <Info {...mdx.frontmatter} prev={prev_link} next={next_link} />
                <SContent>
                    <MDXProvider>{mdx.body}</MDXProvider>
                    {mdx.frontmatter.main_screens
                        ? mdx.frontmatter.main_screens.map((screen, idx) => (
                              <Main
                                  key={idx}
                                  alt={screen.alt}
                                  image={screen.image}
                                  type={screen.type}
                                  show_icon_scroll={screen.show_icon_scroll}
                              />
                          ))
                        : null}
                    {mdx.frontmatter.screens
                        ? mdx.frontmatter.screens.map((screen, idx) => (
                              <Screen
                                  key={idx}
                                  title={screen?.title}
                                  desk={screen?.desk}
                                  show_top_bar={screen.show_top_bar}
                                  show_padding_top={screen.show_padding_top}
                                  show_padding_bottom={
                                      screen.show_padding_bottom
                                  }
                                  bg={screen?.bg}
                                  color={screen?.color}
                                  alt={screen?.alt}
                                  full={screen?.full}
                                  link={screen?.link}
                                  image={screen.image}
                              />
                          ))
                        : null}
                    <Below from={grid.lg}>
                        <ProjectStackBottom {...mdx.frontmatter} />
                        <ProjectNavigation prev={prev_link} next={next_link} />
                    </Below>
                </SContent>
            </SProject>
        </Layout>
    );
};

ProjectPage.propTypes = {
    data: PT.object.isRequired,
};

const SProject = styled.div`
    padding-top: ${header.height};
    ${down("md")} {
    }
    ${down("sm")} {
    }
`;
const SContent = styled.div`
    min-height: calc(100vh - ${header.height});
    background: #353538;
    ${up("lg")} {
        width: 70%;
    }
`;

export default ProjectPage;

export const projectQuery = graphql`
    query ProjectQuery($id: String, $created_at: Date) {
        prev: allMdx(
            filter: {
                fields: { section: { eq: "projects" } }
                frontmatter: { created_at: { gt: $created_at } }
            }
            limit: 1
            sort: { fields: frontmatter___created_at, order: ASC }
        ) {
            edges {
                node {
                    frontmatter {
                        title
                        slug
                        created_at
                    }
                    id
                }
            }
        }
        next: allMdx(
            filter: {
                fields: { section: { eq: "projects" } }
                frontmatter: { created_at: { lt: $created_at } }
            }
            limit: 1
            sort: { fields: frontmatter___created_at, order: DESC }
        ) {
            edges {
                node {
                    frontmatter {
                        title
                        slug
                        created_at
                    }
                    id
                }
            }
        }
        mdx(id: { eq: $id }) {
            id
            body
            frontmatter {
                meta_image {
                    childImageSharp {
                        gatsbyImageData(width: 1200)
                    }
                }
                created_at
                updated_at
                slug
                prev
                next
                title
                sub_title
                text
                time
                price
                workers
                links {
                    link
                    title
                    icon {
                        publicURL
                    }
                }
                stacks
                integration
                tools
                main_screens {
                    alt
                    image {
                        childImageSharp {
                            gatsbyImageData(
                                layout: FULL_WIDTH
                                formats: [AUTO, WEBP]
                            )
                        }
                    }
                    type
                    show_icon_scroll
                }
                screens {
                    color
                    alt
                    link
                    title
                    # desk
                    # show_top_bar
                    # show_padding_top
                    # show_padding_bottom
                    # bg
                    # full
                    image {
                        childImageSharp {
                            gatsbyImageData(
                                layout: FULL_WIDTH
                                formats: [AUTO, WEBP]
                                placeholder: DOMINANT_COLOR
                            )
                        }
                    }
                }
            }
        }
    }
`;
