import React from "react";
import PT from "prop-types";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";

import ProjectStackItem from "~/src/components/project/ProjectWorkerItem";

const ProjectWorkers = ({ workers = [] }) => {
    const data = useStaticQuery(graphql`
        query ProjectWorkersQuery {
            jsons: allFile(
                filter: {
                    sourceInstanceName: { eq: "workers" }
                    ext: { eq: ".json" }
                }
            ) {
                nodes {
                    name
                    childWorkersJson {
                        id
                        title
                        link
                        position
                        avatar
                    }
                }
            }
            avatars: allFile(
                filter: {
                    sourceInstanceName: { eq: "workers" }
                    ext: { eq: ".jpg" }
                }
            ) {
                nodes {
                    name
                    publicURL
                }
            }
        }
    `);
    const selected_jsons = data.jsons.nodes.filter((j) => {
        return workers.includes(j.name);
    });
    selected_jsons.forEach((json) => {
        Object.assign(json, json.childWorkersJson);
        json.avatar = data.avatars.nodes.find(
            (avatar) => avatar.name === json.name
        );
    });
    return (
        <SProjectWorkers>
            {selected_jsons.map((json, idx) => (
                <ProjectStackItem
                    key={idx}
                    link={json.link}
                    avatar={json.avatar.publicURL}
                    title={json.title}
                    position={json.position}
                />
            ))}
        </SProjectWorkers>
    );
};

const SProjectWorkers = styled.div``;

ProjectWorkers.propTypes = {
    workers: PT.arrayOf(PT.string),
};

export default ProjectWorkers;
