import PT from "prop-types";
import React from "react";
import styled from "styled-components";

import { Stack, Workers } from "~/src/components/project";
import { colors, down, fonts, global } from "~/src/config/vars";

const ProjectInfo = ({ stacks, integration, tools, workers }) => {
    return (
        <SBottom>
            {stacks ? (
                <SBottomGroup>
                    <SBottomGroupTitle>Development</SBottomGroupTitle>
                    <Stack stacks={stacks} />
                </SBottomGroup>
            ) : null}
            {integration ? (
                <SBottomGroup>
                    <SBottomGroupTitle>Integration</SBottomGroupTitle>
                    <Stack stacks={integration} />
                </SBottomGroup>
            ) : null}
            {tools ? (
                <SBottomGroup>
                    <SBottomGroupTitle>Tools</SBottomGroupTitle>
                    <Stack stacks={tools} />
                </SBottomGroup>
            ) : null}
            {workers ? (
                <SBottomGroup>
                    <SBottomGroupTitle>Workers</SBottomGroupTitle>
                    <Workers workers={workers} />
                </SBottomGroup>
            ) : null}
        </SBottom>
    );
};

ProjectInfo.propTypes = {
    stacks: PT.array,
    integration: PT.array,
    tools: PT.array,
    workers: PT.array,
};

const padding_right = "1.5em 2em";
const padding_right_lg = "1em 1.5em";
const padding_right_md = "1.5em 1.5em";
const padding_right_sm = `15px ${global.container_padding} 15px ${global.container_padding}`;

const SBottom = styled.div`
    padding: ${padding_right};
    border-top: 1px solid ${colors.black_light};
    ${down("lg")} {
        padding: ${padding_right_lg};
    }
    ${down("md")} {
        padding: ${padding_right_md};
        padding-bottom: 5em;
    }
    ${down("sm")} {
        padding: ${padding_right_sm};
        padding-top: 1.5em;
        padding-bottom: 1em;
    }
`;
const SBottomGroup = styled.div`
    margin-top: 2em;
    &:first-of-type {
        margin-top: 0;
    }
    ${down("sm")} {
        margin-top: 1.2em;
        &:first-of-type {
            margin-top: 0;
        }
    }
`;
const SBottomGroupTitle = styled.div`
    margin-bottom: 0.8em;
    font-weight: ${fonts.fw_medium};
    font-size: ${fonts.fs_18};
    ${down("sm")} {
        margin-bottom: 0;
        ${SBottomGroup}:last-child & {
            margin-bottom: 0.4em;
        }
    }
`;

export default ProjectInfo;
