import React, { useCallback, useEffect } from "react";
import PT from "prop-types";
import CN from "classnames";
import styled from "styled-components";
import { position } from "polished";
import { Link, navigate } from "gatsby";

import { ArrowLeftIcon, ArrowRightIcon } from "~/src/components/icons";
import { colors, down, fonts, global } from "~/src/config/vars";

const ProjectNavigation = ({ prev, next }) => {
    const escFunction = useCallback((event) => {
        if (event.keyCode === 37 && prev) {
            navigate(`/projects/${prev.slug}`);
        }
        if (event.keyCode === 39 && next) {
            navigate(`/projects/${next.slug}`);
        }
    }, []);
    useEffect(() => {
        document.addEventListener("keydown", escFunction);
        return () => {
            document.removeEventListener("keydown", escFunction);
        };
    }, [escFunction]);

    return (
        <SProjectNavigation>
            {prev && (
                <SNavigationItem
                    className={CN(`SPrev`, {
                        active: prev.title && prev.slug,
                    })}
                >
                    <Link to={`/projects/${prev.slug}`}>
                        <ArrowLeftIcon />
                        <span>{prev.title}</span>
                    </Link>
                </SNavigationItem>
            )}
            {next && (
                <SNavigationItem
                    className={CN(`SNext`, {
                        active: next.title && next.slug,
                    })}
                >
                    <Link to={`/projects/${next.slug}`}>
                        <span>{next.title}</span>
                        <ArrowRightIcon />
                    </Link>
                </SNavigationItem>
            )}
        </SProjectNavigation>
    );
};

const padding_right = "1em 2em";
const padding_right_lg = "1em 1.5em";
const padding_right_md = "1em 1.5em";
const padding_right_sm = `15px ${global.container_padding} 2em`;

const SProjectNavigation = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    ${position("fixed", null, "0", "0", null)};
    width: calc(30% - 1px);
    z-index: 4;
    border-top: 1px solid ${colors.black_light};
    background-color: ${colors.black};

    padding: ${padding_right};
    ${down("lg")} {
        padding: ${padding_right_lg};
    }
    ${down("md")} {
        padding: ${padding_right_md};
        width: 100%;
    }
    ${down("sm")} {
        padding: ${padding_right_sm};
        position: relative;
    }
`;
const SNavigationItem = styled.div`
    max-width: 50%;
    a {
        display: flex;
        align-items: center;
        transition: all 0.2s ease-in-out;
        font-size: ${fonts.fs_16};
        color: ${colors.white};
        transition: all 0.2s;
        font-variation-settings: "ital" 100;

        svg {
            width: 1.6em;
            height: 1em;
            display: inline-flex;
        }
        span {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
        &:hover {
            color: ${colors.red_light};

            font-variation-settings: "ital" 1000;
        }
    }
    &.SPrev {
        span {
            padding-left: 0.5em;
        }
    }
    &.SNext {
        margin-left: auto;
        span {
            padding-right: 0.5em;
        }
    }
`;

ProjectNavigation.propTypes = {
    prev: PT.shape({
        slug: PT.any.isRequired,
        title: PT.any.isRequired,
    }),
    next: PT.shape({
        slug: PT.any.isRequired,
        title: PT.any.isRequired,
    }),
};

export default ProjectNavigation;
